<template>
    <div class="search-input" ref="searchInputRef" :class="{ 'has-warning': !!warningMessage }">
        <custom-icon  v-if="!warningMessage" :icon="loading ? 'Loading' : 'Search'" />
        <input
            :id="id"
            class="form-control"
            ref="inputRef"
            placeholder="Search"
            :value="localValue"
            @keydown="emit('keydown', $event)"
            @keyup="
                updateHasValue($event)
                onInputKeyup($event)
            "
            @keyup.esc="clearSearch"
        />
        <custom-icon class="warning-icon" :id="`${id}-error`" v-if="warningMessage" icon="Warning" :title="warningMessage" />
        <custom-icon icon="Remove" @click="clearSearch" title="Clear search (ESC)" />
    </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash'
import { ref, defineEmits, defineProps, defineExpose, onMounted, PropType } from 'vue'

//#region DEFINE VARIABLES
const emit = defineEmits<{
    (e:"input", value: string)
    (e:"keydown", value: KeyboardEvent)
    (e:"keyup", value: string)
}>()

const props = defineProps({
    id: { type: String, default: "searchInput" },
    value: { type: String, default: "" },
    debounceTime: { type: Number, default: 500 },
    minimumCharacters: { type: Number, default: 2 },
    warningMessage: { type: String as PropType<string|null>, default: null} ,
    loading: { type: Boolean },
})

const localValue = ref("")
const onInputKeyup = ref()
const searchInputRef = ref()
const inputRef = ref()
const restrictedCharacters = /'/g
//#endregion

//#region INITIALIZE
initialize()

onMounted(() => {
    updateHasValueClass(!!props.value)
})

async function initialize(){
    localValue.value = props.value
    onInputKeyup.value = debounce(onKeyup, props.debounceTime)
}

//Expose focus and clearSearch for multi-select component
defineExpose({focus, clearSearch})
// #endregion

function emitKeyupValue(val: string) {
    let newVal = val.trimStart().trimEnd()
    newVal = newVal.replace(restrictedCharacters, "");
    emit("input", newVal)
    emit("keyup", newVal)
}

function onKeyup(event: any) {
    localValue.value = event.target.value
    
    //Search input must be empty or greater than minimum character count
    const triggerSearch = !localValue.value.length || localValue.value.length >= props.minimumCharacters
    if (triggerSearch) emitKeyupValue(localValue.value)
}

function updateHasValue(event: any) {
    updateHasValueClass(event.target.value)
}

function updateHasValueClass(hasValue: boolean) {
    // Tried binding search-input's class to a boolean,
    // but: the first time a character is input in the search,
    // hasValue would get set to true, and all bindings get refreshed,
    // including the input's binding to localValue, which is still null,
    // which results in the input going blank again
    const el = searchInputRef.value as Element as HTMLElement

    if (hasValue) {
        if (el && !el.classList.contains('has-value')) {
            el.classList.add('has-value')
        }
    } else {
        if (el && el.classList.contains('has-value')) {
            el.classList.remove('has-value')
        }
    }
}

function clearSearch() {
    updateHasValueClass(false)
    localValue.value = ''
    emitKeyupValue(localValue.value)
}

function focus() {
    const el = inputRef.value as HTMLElement
    el.focus()
}
</script>

<style lang="scss" scoped>
.search-input {
    position: relative;
    display: flex;

    input {
        margin: 0;
        padding-left: 1.625rem;
    }

    &.has-warning.has-value {
        input {
            // moves the input border all the way to
            // the right when both icons are showing
            margin-right: -3rem;
        }
    }

    .custom-icon {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        height: 100%;
        width: 1.625rem;

        &.loading,
        &.search {
            left: 0;
            color: $dark-gray;
            font-size: 100%; // because bootstrap makes icons inside dropdowns 125%?
        }

        &.remove {
            right: 0;
        }
    }

    &:not(.has-value) {
        .remove {
            display: none;
        }
    }
}
</style>
