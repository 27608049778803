import startCase from 'lodash/startCase'
import { axiosMarketDataService } from './index'
import { ArmIndexType, Prime, Sofr, WeeklyTreasuryRate } from '@/common/models'
import moment from 'moment'

class MarketDataService {
    async getAllRates(rate: RateType, params?: URLSearchParams) {
        const response = await axiosMarketDataService.get(`/rates/${rate}`, { params })
        return response.data
    }

    async getAllDiscountRates(bank: DiscountRateBank, params?: URLSearchParams) {
        const response = await axiosMarketDataService.get(`/rates/discount-rate/${bank}`, { params })
        return response.data
    }

    async getRate(rate: RateType, key: string) {
        const response = await axiosMarketDataService.get(`/rates/${rate}/${key}`)
        return response.data
    }

    async getDiscountRate(bank: DiscountRateBank, key: string) {
        const response = await axiosMarketDataService.get(`/rates/discount-rate/${bank}/${key}`)
        return response.data
    }

    async postRate(rate: RateType, rateData: any) {
        const response = await axiosMarketDataService.post(`/rates/${rate}`, rateData)
        return response.data
    }

    async postDiscountRate(bank: DiscountRateBank, rateData: any) {
        const response = await axiosMarketDataService.post(`/rates/discount-rate/${bank}`, rateData)
        return response.data
    }

    async importRate(rate: RateType) {
        const response = await axiosMarketDataService.post(`/rates/${rate}/import?count=3`)
        return response.data
    }

    async getImportRateDetails(rate: RateType) {
        const response = await axiosMarketDataService.get(`/rates/${rate}/import/source-details`)
        return response.data
    }

    async getRateAsOf(indexType: ArmIndexType, date: Date | null) {
        if(!indexType || !date) return null

        let rate = ''
        switch(indexType){
            case ArmIndexType.TreasuryOneYear: rate = RateType.weeklyTreasury; break;
            case ArmIndexType.TreasuryThreeYear: rate = RateType.weeklyTreasury; break;
            case ArmIndexType.TreasuryFiveYear: rate = RateType.weeklyTreasury; break;
            case ArmIndexType.WsjPrime: rate = RateType.prime; break;
            case ArmIndexType.SofrThirtyDayAverage: rate = RateType.sofr; break;
        }
        const dateString = moment.utc(date).format('YYYY-MM-DD')
        const response = await axiosMarketDataService.get(`/rates/${rate}/effective/${dateString}`)

        let rateValue = 0
        switch(indexType){
            case ArmIndexType.TreasuryOneYear: rateValue = (response.data as WeeklyTreasuryRate)?.oneYear; break;
            case ArmIndexType.TreasuryThreeYear: rateValue = (response.data as WeeklyTreasuryRate)?.threeYear; break;
            case ArmIndexType.TreasuryFiveYear: rateValue = (response.data as WeeklyTreasuryRate)?.fiveYear; break;
            case ArmIndexType.SofrThirtyDayAverage: rateValue = (response.data as Sofr)?.rate; break;
            case ArmIndexType.WsjPrime: rateValue = (response.data as Prime)?.rate; break;            
        }
        return rateValue
    }
}

export const marketDataService = new MarketDataService()
export const enum RateType {
    apor = 'apor',
    prime = 'prime',
    libor = 'libor',
    loanLimit = 'loan-limits',
    freddie = 'fhlmc',
    dailyTreasury = 'treasury/daily',
    weeklyTreasury = 'treasury/weekly',
    sofr = 'sofr',
    commercial90DayFinancialPaper = 'commercial-90-day',
    fannieMayHistoricalDailyYields = 'fannie-mae-daily-yield',
    discountRate = 'discount-rate',
    treasuryBill = 'treasury/treasury-bill',
    tennesseeMaxRate = 'tennessee-max-rate',
    tennesseePrimeRate = 'tennessee-prime-rate',
    missouriMarketRate = 'missouri-market-rate'
}

export const enum DiscountRateBank {
    atlanta = 'atlanta',
    boston = 'boston',
    chicago = 'chicago',
    cleveland = 'cleveland',
    dallas = 'dallas',
    kansascity = 'kansascity',
    minneapolis = 'minneapolis',
    newyork = 'newyork',
    philadelphia = 'philadelphia',
    richmond = 'richmond',
    sanfrancisco = 'sanfrancisco',
    stlouis = 'stlouis'
}

export function getBankDisplayName(bank: DiscountRateBank) {
    switch (bank) {
        case DiscountRateBank.kansascity:
            return 'Kansas City'
        case DiscountRateBank.newyork:
            return 'New York'
        case DiscountRateBank.sanfrancisco:
            return 'San Francisco'
        case DiscountRateBank.stlouis:
            return 'St. Louis'
        default:
            return startCase(bank)
    }
}
