export default [
    {
        path: 'market-data',
        redirect: 'market-data/weekly-treasury'
    },
    {
        path: 'market-data/weekly-treasury',
        name: 'weekly-treasury-grid',
        component: () => import('@/common/views/system/rates/weekly-treasury/weekly-treasury-grid.vue'),
        meta: {
            title: 'Treasury (Weekly)',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/weekly-treasury/:date',
        name: 'weekly-treasury',
        component: () => import('@/common/views/system/rates/weekly-treasury/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/apor',
        name: 'apor-grid',
        component: () => import('@/common/views/system/rates/apor/apor-grid.vue'),
        meta: {
            title: 'APOR (HPM)',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/apor/:date',
        name: 'apor',
        component: () => import('@/common/views/system/rates/apor/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/loan-limits',
        name: 'loan-limits-grid',
        component: () => import('@/common/views/system/loan-limit/loan-limits-grid.vue'),
        meta: {
            title: 'Loan Limits',
            readPermission: 'system.marketdata.loanlimits',
            writePermission: 'system.marketdata.loanlimits:write'
        }
    },
    {
        path: 'market-data/loan-limits/:key',
        name: 'loan-limit',
        component: () => import('@/common/views/system/loan-limit/loan-limit.vue'),
        meta: {
            readPermission: 'system.marketdata.loanlimits',
            writePermission: 'system.marketdata.loanlimits:write'
        }
    },
    {
        path: 'market-data/daily-treasury',
        name: 'daily-treasury-grid',
        component: () => import('@/common/views/system/rates/daily-treasury/daily-treasury-grid.vue'),
        meta: {
            title: 'Treasury (HCM)',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/daily-treasury/:date',
        name: 'daily-treasury',
        component: () => import('@/common/views/system/rates/daily-treasury/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/freddie-mac',
        name: 'freddie-mac-grid',
        component: () => import('@/common/views/system/rates/freddie-mac/freddie-mac-grid.vue'),
        meta: {
            title: 'Freddie Mac (PMMS)',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/freddie-mac/:date',
        name: 'freddie-mac',
        component: () => import('@/common/views/system/rates/freddie-mac/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    
    {
        path: 'market-data/sofr',
        name: 'sofr-grid',
        component: () => import('@/common/views/system/rates/sofr/sofr-grid.vue'),
        meta: {
            title: 'SOFR',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/sofr/:date',
        name: 'sofr',
        component: () => import('@/common/views/system/rates/sofr/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/libor',
        name: 'libor-grid',
        component: () => import('@/common/views/system/rates/libor/libor-grid.vue'),
        meta: {
            title: 'LIBOR',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/libor/:date',
        name: 'libor',
        component: () => import('@/common/views/system/rates/libor/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/prime',
        name: 'prime-grid',
        component: () => import('@/common/views/system/rates/prime/prime-grid.vue'),
        meta: {
            title: 'Prime',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/prime/:date',
        name: 'prime',
        component: () => import('@/common/views/system/rates/prime/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/commercial-90-day-financial-paper',
        name: 'commercial-90-day-financial-paper-grid',
        component: () => import('@/common/views/system/rates/commercial-90-day-financial-paper/commercial-90-day-financial-paper-grid.vue'),
        meta: {
            title: 'Commercial 90-Day Financial Paper',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/commercial-90-day-financial-paper/:date',
        name: 'commercial-90-day-financial-paper',
        component: () => import('@/common/views/system/rates/commercial-90-day-financial-paper/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/fannie-mae-historical-daily-required-net-yields',
        name: 'fannie-mae-historical-daily-required-net-yields-grid',
        component: () => import('@/common/views/system/rates/fannie-mae-historical-daily-required-net-yields/fannie-mae-historical-daily-required-net-yields-grid.vue'),
        meta: {
            title: 'Commercial 90-Day Financial Paper',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/fannie-mae-historical-daily-required-net-yields/:date',
        name: 'fannie-mae-historical-daily-required-net-yields',
        component: () => import('@/common/views/system/rates/fannie-mae-historical-daily-required-net-yields/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/federal-reserve-discount-rates',
        name: 'federal-reserve-discount-rates',
        component: () => import('@/common/views/system/rates/discount-rates/discount-rates.vue'),
        meta: {
            title: 'Federal Reserve Discount Rates',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/federal-reserve-discount-rates/:bank/:date',
        name: 'federal-reserve-discount-rate',
        component: () => import('@/common/views/system/rates/discount-rates/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/treasury-bill-rates',
        name: 'treasury-bill-rates-grid',
        component: () => import('@/common/views/system/rates/treasury-bill/treasury-bill-grid.vue'),
        meta: {
            title: 'Treasury Bill Rates',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/treasury-bill-rates/:date',
        name: 'treasury-bill-rates',
        component: () => import('@/common/views/system/rates/treasury-bill/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/tennessee-max-rates',
        name: 'tennessee-max-rates-grid',
        component: () => import('@/common/views/system/rates/tennessee-max-rate/tennessee-max-rate-grid.vue'),
        meta: {
            title: 'Tennessee Maximum Effective Interest Rates',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/tennessee-max-rates/:date',
        name: 'tennessee-max-rates',
        component: () => import('@/common/views/system/rates/tennessee-max-rate/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/tennessee-prime-rates',
        name: 'tennessee-prime-rates-grid',
        component: () => import('@/common/views/system/rates/tennessee-prime-rate/tennessee-prime-rate-grid.vue'),
        meta: {
            title: 'Tennessee Prime Formula Rates',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/tennessee-prime-rates/:date',
        name: 'tennessee-prime-rates',
        component: () => import('@/common/views/system/rates/tennessee-prime-rate/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/missouri-market-rates',
        name: 'missouri-market-rates-grid',
        component: () => import('@/common/views/system/rates/missouri-market-rate/missouri-market-rate-grid.vue'),
        meta: {
            title: 'Missouri Market Rate',
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    },
    {
        path: 'market-data/missouri-market-rates/:date',
        name: 'missouri-market-rates',
        component: () => import('@/common/views/system/rates/missouri-market-rate/index.vue'),
        meta: {
            readPermission: 'system.marketdata.indices',
            writePermission: 'system.marketdata.indices:write'
        }
    }
]